import AModel from './AModel';

class TruckDamage extends AModel {
	id: string;
	truck: string;
	number: string;
	is_repaired: boolean;
	state: TruckDamageState;
	type: TruckDamageType;
	description: {
		short: string | null;
		partner: string | null;
	};
	billing_info: {
		total: number;
		paid: number;
		currency: string | null;
	};
	constructor(data?: Partial<TruckDamage>) {
		super();

		this.id = data?.id || '';
		this.truck = data?.truck || '';
		this.number = data?.number || '';
		this.is_repaired = data?.is_repaired || false;
		this.state = data?.state || TruckDamageState.Open;
		this.type = data?.type || TruckDamageType.Damage;
		this.description = {
			short: data?.description?.short || null,
			partner: data?.description?.partner || null
		};
		this.billing_info = {
			total: data?.billing_info?.total || 0,
			paid: data?.billing_info?.paid || 0,
			currency: data?.billing_info?.currency || null
		};
	}

	toDTO(): Record<string, unknown> {
		return {
			id: this.id,
			truck: this.truck,
			number: this.number,
			is_repaired: this.is_repaired,
			state: this.state,
			type: this.type,
			description: {
				short: this.description.short,
				partner: this.description.partner
			},
			billing_info: {
				total: this.billing_info.total,
				paid: this.billing_info.paid,
				currency: this.billing_info.currency
			}
		};
	}

	copy(): TruckDamage {
		return new TruckDamage(this.toDTO() as Partial<TruckDamage>);
	}
}

export enum TruckDamageState {
	Open = 'OPEN',
	Work = 'WORK',
	Cost = 'COST',
	ToBill = 'BILL',
	Sent = 'SENT',
	Paid = 'PAID',
	Done = 'DONE',
	Canceled = 'CANCELED'
}

export enum TruckDamageType {
	Recovery = 'RECOVERY',
	Damage = 'DAMAGE',
	Fuelsensor = 'FUELSENSOR'
}

export default TruckDamage;
