import AModel from './AModel';
import Truck from './truck';

class Downtime extends AModel {
	id: string;
	time_end: string;
	time_start: string;
	truck: Truck | string | null;
	freetext: string;
	date_create: string;
	date_update: string;
	user_create: string;
	user_update: string;
	type: DowntimeState;

	constructor(opt?: Partial<Downtime>) {
		super();

		//default values (all props)
		this.id = '';
		this.time_end = '';
		this.time_start = '';

		this.truck = null;

		this.type = DowntimeState.Unplanned;
		this.freetext = '';
		this.date_create = '';
		this.date_update = '';
		this.user_create = '';
		this.user_update = '';

		if (opt) {
			//sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign({}, ...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] })));

			//assign values passed to the constructor
			Object.assign<Downtime, Partial<Downtime>>(this, sanitized);

			if (this.truck && typeof this.truck != 'string') {
				this.truck = new Truck(this.truck);
			}
		}
	}

	copy(): Downtime {
		const copy = new Downtime(this.toObject());
		return copy;
	}

	toDTO(): Record<string, unknown> {
		const dto = this.toObject();

		delete dto.date_create;
		delete dto.date_update;
		delete dto.user_create;
		delete dto.user_update;
		delete dto.truck;
		delete dto.id;

		return dto;
	}
}

export enum DowntimeState {
	Planned = 'PLANNED',
	Unplanned = 'UNPLANNED'
}

export default Downtime;
